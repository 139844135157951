import { useState } from 'react';

import { LoaderComponent, InstagramIcon, FacebookIcon } from '../../../common';

import styles from './header.module.css';

export const HeaderComponent = () => {
  const [isInstagramHovered, setIsInstagramHovered] = useState<boolean>(false);
  const [isFacebookHovered, setIsFacebookHovered] = useState<boolean>(false);

  return (
    <div className={styles.container}>
      <LoaderComponent />
      <div>
        <img
          src='assets/images/ship-construct-transparent-logo.png'
          alt='ship'
          className={styles.logo}
        />
        <div className={styles.slogan}>
          <h3>
            <span className={styles['ship-blue']}>S</span>ervicii.
          </h3>
          <h3>
            <span className={styles['ship-blue']}>H</span>idroizolații.
          </h3>
          <h3>
            <span className={styles['ship-blue']}>I</span>ndustrie.
          </h3>
          <h3>
            <span className={styles['ship-blue']}>P</span>eisagistică.
          </h3>
        </div>
      </div>
      <div className={styles.content}>
        <h3 className={styles.title}>Zile ploioase și proiecte noi!</h3>
        <p className={styles.description}>
          Suntem în plin proces de creare a unui site nou și captivant.
          <br />
          Între timp, ne puteți contacta la adresa de e-mail:{' '}
          <a href='mailto:contact@shipconstruct.ro'>contact@shipconstruct.ro</a>
          .
        </p>
        <div className={styles.socials}>
          <a
            href='https://www.instagram.com/shipconstruct.ro/'
            target='_blank'
            rel='noreferrer'
            onMouseOver={() => setIsInstagramHovered(true)}
            onMouseLeave={() => setIsInstagramHovered(false)}
          >
            <InstagramIcon fill={isInstagramHovered ? '#137eb0' : '#388640'} />
          </a>
          <a
            href='https://www.facebook.com/profile.php?id=100094709163877'
            target='_blank'
            rel='noreferrer'
            onMouseOver={() => setIsFacebookHovered(true)}
            onMouseLeave={() => setIsFacebookHovered(false)}
          >
            <FacebookIcon fill={isFacebookHovered ? '#137eb0' : '#388640'} />
          </a>
        </div>
      </div>
    </div>
  );
};
