import HomePage from './pages/home/home.page';

import styles from './app.module.css';

function App() {
  return (
    <div className={styles.root}>
      <HomePage />
    </div>
  );
}

export default App;
